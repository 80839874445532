/* ProfilePage.css */

.outer-container {
  display: flex;
  justify-content: center;
}
.links {
  height: 2rem;
  width: 2rem;
  padding: 1rem;
  max-width: 33vw;
}
.link {
  height: 2rem;
  width: 2rem;
}

.menu-button {
  display: none;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.left-photo,
.right-photo {
  width: 20%; /* Adjust the width as needed */
  height: 100vh;
  /* You can add additional styling if needed */
}

.photo-container img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px; /* Optional: Add border-radius for rounded corners */
}

.master-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #3b5998;
  color: black;
  font-family: Arial, sans-serif;
  min-height: 220vh;
  min-width: 65%;
}

.main-header {
  background-color: #3b5998;
  width: 100%;
  padding: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.search-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  ul {
    list-style: none;
    display: flex;
    gap: 20px;
    padding: 0;
  }

  a {
    text-decoration: underline;
    color: #fff;
    font-weight: bold;
    font-size: 27px;
  }

  .navbar-brand {
    margin-left: auto;
  }
}

.page-content-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-width: 80%;
  max-width: 1200px;
  margin: 20px 0;
}

.profile-sidebar {
  width: 30%;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  height: 190vh;

  h1 {
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
  }

  .profile-pic {
    width: 70%;
    border-radius: 8px;
  }

  .personal-msg {
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
  }

  .gender-age,
  .last-login {
    list-style: none;
    padding: 0;
    margin-top: 10px;
    font-size: px;

    li {
      margin-bottom: 5px;
      font-size: 25px;
      justify-content: center;
    }
  }

  .pics-videos {
    font-size: 14px;
    margin-top: 10px;

    a {
      color: black;
      font-weight: bold;
      text-decoration: none;
      font-size: 50px;
    }
  }
}

.main {
  width: 70%;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  margin-left: 20px;
  height: 190vh;

  h2,
  h3 {
    color: black;
  }

  p {
    line-height: 1.5;
  }

  iframe {
    width: 100%;
    height: 300px;
    margin-top: 20px;
  }
}

.footer {
  width: 100%;
  padding: 10px;
  background-color: #4267b2;
  color: #fff;
  text-align: center;
  position: fixed;
  bottom: 0;
}

.friend-pic-container img {
  width: 100%;
  height: auto;
  border-radius: 8px; /* Optional: Add border-radius for rounded corners */
}

.friend-pic-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

/* Media query for Samsung S9 */
/* Media query for Samsung S9 */
@media only screen and (min-width: 200px) and (max-width: 600px) {
  .menu-button {
    display: block;
  }

  .navbar {
    flex-direction: column;

    &.menu-visible {
      display: flex !important;
    }

    ul {
      display: flex;
      flex-direction: column;
    }
  }

  .menu-visible {
    height: 100vh;

    .main-header {
      height: 100vh; /* Expand header to 80vh when menu is visible */
    }
  }

  .page-content-container,
  .profile-sidebar,
  .main {
    display: flex;
    width: 100%;
    padding: 10px;
    flex-direction: column;
    align-items: center;
    height: 300vh;
  }

  .photo-container.left-photo,
  .photo-container.right-photo {
    display: none; /* Hide left and right photo containers on smaller screens */
  }

  .friend {
    justify-content: center;
    align-items: center;
  }

  .friend-pic-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .bio {
    font-size: 14px;
  }
}
