/* ProfilePage.css */
body {
  font-family: Arial, sans-serif;
  background-color: #e3e3e3;
  margin: 0;
}

.master-container {
  max-width: 1500px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.main-header {
  background-color: #007acc;
  padding: 20px 0;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  ul {
    list-style: none;
    display: flex;
    gap: 20px;
    padding: 0;
  }

  a {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
  }

  .navbar-center {
    display: flex;
    align-items: center;
  }

  .navbar-brand {
    margin-left: auto;
  }
}

.page-content-container {
  padding: 20px;
}

/* Add more styles as needed */
